"use strict";

require('./collapsePlugin');

module.exports = function () {
	$('[dropdown-catalog]').each(function () {
		var $block = $(this),
			$button = $block.find('[collapse-button]');

		$button.on('click', function (event) {
			if(!$block.hasClass('open')) event.preventDefault();
		});

		$block.collapse({
			preventDefault : false,
			animation : false
		});



	});
};

