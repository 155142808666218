"use strict";
var $ = window.jQuery = window.$ = require("./..\\..\\bower_components\\jquery\\dist\\jquery.js");

require('./modules/parsleyInit');
require('./modules/collapsePlugin');

//init wrappers for plugins
$(function () {
    require('./modules/slickSliderInit')();
    require('./modules/mfpInit')();
	require('./modules/dropDownCatalogInit')();
});


