"use strict";

require("./..\\..\\..\\bower_components\\slick-carousel\\slick\\slick.min.js");

module.exports = function () {
	$('[slick-slider]').each(function () {
		if($(this).hasClass('slick-initialized')) return;

		var $slider = $(this),
			slideQuery = $slider.find('[slick-slider-item]').length ? '[slick-slider-item]' : '',
			$nav = $slider.find('[slick-slider-nav]');

		if(!$nav.length) {
			$nav = $('<div class="mp-slider-overlay" slick-slider-nav>');
			$slider.one('init', function () {
				$slider.prepend($nav);
			});
		}

		$slider.slick({
			dots : true,
			prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="previous"></button>',
			nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="next"></button>',
			customPaging: function(slider, i) {
				return '<button type="button" data-role="none"></button>';
			},
			slide: slideQuery,
			appendArrows: $nav,
			appendDots: $nav,
			autoplay: true,
			autoplaySpeed: 5000
		});
	});
};