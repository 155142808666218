"use strict";

require("./..\\..\\..\\bower_components\\magnific-popup\\dist\\jquery.magnific-popup.js");

module.exports = function () {
	$('[image-popup-link]').each(function () {
		var $link = $(this);

		if( $link.closest('[image-popup-gallery]').length ) return;

		$link.magnificPopup({
			type: 'image',
			image: {
				tError: '<a href="%url%">Изображение</a> недоступно.'
			},
			callbacks: {
				beforeOpen: function() {
					this.st.mainClass = 'mfp-zoom-in';
				}
			},
			removalDelay: 300
		});
	});

	$('[image-popup-gallery]').magnificPopup({
		delegate: '[image-popup-link]',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1],
			tCounter: '%curr% из %total%'
		},
		type: 'image',

		image: {
			tError: '<a href="%url%">Изображение</a> недоступно.'
		},
		callbacks: {
			beforeOpen: function() {
				this.st.mainClass = 'mfp-zoom-in';
			}
		},
		removalDelay: 300
	});

	$('[inline-popup-link]').each(function () {
		var $link = $(this),
			$wrap = $link.closest('[inline-popup-link-wrap]'),
			$src =  $wrap.length ? $wrap.find('[inline-popup]') : $($link.attr('href'));

		$link.magnificPopup({
			items: {
				src: $src,
				type: 'inline'
			},
			callbacks: {
				beforeOpen: function() {
					this.st.mainClass = 'mfp-zoom-in';
				}
			},
			removalDelay: 300
		});
	});

	$('[video-popup-item]').each(function () {
		var $item = $(this),
			$links = $item.find('a'),
			title = $item.find('[video-popup-title]').text(),
			caption = $item.find('[video-popup-caption]').html();

		$links.magnificPopup({
			type: 'iframe',
			iframe: {
				markup: '<div class="video-popup">'+
				            '<div class="mfp-close"></div>'+
							'<h2 class="video-popup-title">'+title+'</h2>'+
				            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
							'<div class="video-popup-caption">'+caption+'</div>'+
						'</div>'
			}
		});
	});

	$('[mfp-opened]').each(function () {
		var $popup = $(this);

		$.magnificPopup.open({
			items: {
				src: $popup,
				type: 'inline'
			}
		});
	});



};

$.magnificPopup.instance.next = function() {
	var $wrap = $('.mfp-gallery');

	$wrap.addClass('mfp-right-begin');
	setTimeout(function () {
		$wrap.removeClass('mfp-right-begin');
		$.magnificPopup.proto.next.call(this);
		$wrap.addClass('mfp-right-end');
		setTimeout(function () {
			$wrap.removeClass('mfp-right-end');
		}, 200);
	}, 200);
};

$.magnificPopup.instance.prev = function() {
	var $wrap = $('.mfp-gallery');

	$wrap.addClass('mfp-left-begin');
	setTimeout(function () {
		$wrap.removeClass('mfp-left-begin');
		$.magnificPopup.proto.prev.call(this);
		$wrap.addClass('mfp-left-end');
		setTimeout(function () {
			$wrap.removeClass('mfp-left-end');
		}, 200);
	}, 200);
};